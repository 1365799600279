.hover{
    @apply cursor-pointer  text-sm font-medium text-black ;
    position: relative;
    // opacity: 0.7;
    &::after{
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 0px;
        height: 3px;
        background-color: transparent;
        transition: all 0.4s;
    
    }
    &:hover{
        opacity: 1;
        &::after{
            width: 70%;
            background-color: #FF8F01;
            transform: translateX(-0%);
        } 
       
    }
}