.extracurricular-slide-arrows {
  &[data-direction="right"] {
    @apply right-0 lg:-right-20;
  }

  &[data-direction="left"] {
    @apply left-0 lg:-left-20;
  }

  @apply absolute top-1/2 flex h-[50px] w-[50px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-transparent transition-transform duration-100 ease-in hover:scale-110;
}

.buckets-slide-dots {
  @apply relative top-full left-1/2 flex w-full -translate-x-1/2 items-center justify-center lg:mt-10;

  .slider-dot {
    @apply mr-3 h-3 w-3 cursor-pointer rounded-full border border-arrivalhub-orange-800 bg-white;
    &.slider-dot-active {
      @apply bg-arrivalhub-orange-800;
    }
  }
}
