.slider-arrow {
  &[data-direction="right"] {
    @apply right-0 lg:right-2;
  }

  &[data-direction="left"] {
    @apply left-0 lg:left-2;
  }

  @apply absolute top-1/2 flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full bg-white transition-transform duration-100 ease-in hover:scale-110;

  img,
  svg {
    &[data-direction="left"] {
      @apply translate-x-[-2px];
    }

    &[data-direction="right"] {
      @apply translate-x-[2px];
    }

    @apply focus:outline-none;
  }
}

.slider-dots {
  @apply absolute bottom-4 left-1/2 flex w-full -translate-x-1/2 items-center justify-center;

  .slider-dot {
    @apply mr-[5px] h-3 w-3 cursor-pointer rounded-full border border-arrivalhub-orange-800 bg-white;
    &.slider-dot-active {
      @apply bg-arrivalhub-orange-800;
    }
  }
}

.slider-content {
  @apply flex h-full;

  .slider-slide {
    @apply h-auto shrink-0;
    &.slider-slide-active {
      @apply h-auto;
    }
  }

  &[data-effect="fade"] {
    @apply grid h-max w-full;

    .slider-slide {
      grid-area: 1/1;
      @apply bg-white opacity-0;
      &.slider-slide-active {
        @apply opacity-100;
      }
    }
  }
}

.slider-content-vertical {
  @apply mx-auto flex flex-col;
}
