.story-card {
  @apply relative rounded-3xl bg-white;
  box-shadow: 0px 94.2489px 75.3991px rgba(0, 0, 0, 0.02),
    0px 61.0873px 44.1574px rgba(0, 0, 0, 0.0151852),
    0px 36.3033px 24.016px rgba(0, 0, 0, 0.0121481),
    0px 18.8498px 12.2524px rgba(0, 0, 0, 0.01),
    0px 7.67954px 6.14363px rgba(0, 0, 0, 0.00785185),
    0px 1.74535px 2.9671px rgba(0, 0, 0, 0.00481481);

  .card-background {
    @apply relative;
    .inner-card {
      @apply absolute bottom-[-60px] left-1/2 h-max w-4/5 -translate-x-1/2 rounded-xl bg-white px-4 pt-20 pb-6 shadow-[0px_16px_40px_rgba(181,181,181,0.25)] lg:w-2/3 lg:px-20;
      .card-image {
        @apply absolute left-1/2 top-[-50px] h-auto w-auto -translate-x-1/2 lg:top-[-80px];
      }

      .card-content {
        @apply mb-6 block lg:mb-3 lg:text-center;
      }
      .card-author {
        @apply flex items-center text-center font-bold text-arrivalhub-dark-blue-400 lg:block;

        .author-name {
          @apply font-bold text-arrivalhub-orange-800 lg:mb-1 lg:text-2xl;
        }

        .author-country {
        }
      }
    }
  }
}

.story-slide-arrows {
  &[data-direction="right"] {
    @apply right-0 lg:-right-10;
  }

  &[data-direction="left"] {
    @apply left-0 lg:-left-10;
  }

  @apply absolute top-[calc(50%-80px)] flex h-[50px] w-[50px] -translate-y-[calc(50%-60px)] cursor-pointer items-center justify-center rounded-full bg-transparent transition-transform duration-100 ease-in hover:scale-110;
}
